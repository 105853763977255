// src/firebase.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyAiMHFIwg27rddN6B1EvePR9UOiQZ-SCdo",
    authDomain: "tokenrivals-85313.firebaseapp.com",
    projectId: "tokenrivals-85313",
    storageBucket: "tokenrivals-85313.firebasestorage.app",
    messagingSenderId: "112664950197",
    appId: "1:112664950197:web:e04879366bd669e6c995fc",
    measurementId: "G-M5VNWKHWD6"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase services
export const auth = getAuth(app);
export const analytics = getAnalytics(app);
export default app;