import { useEffect, useState } from "react";
import {
  Badge,
  Container,
  Description,
  RColumn,
  RRow,
  Space,
  Title2,
} from "../../componets/baseComp";
import { CommunityRes, UserResponse } from "../../types/basetype";
import { apiRequest } from "../../utils/utils";
import { GradientButton } from "../../componets/buttons";
import {
  LuCircleDollarSign,
  LuGlobe,
  LuInstagram,
  LuSend,
  LuTrophy,
  LuTwitter,
} from "react-icons/lu";

export default function ProfilePage({ userData }: { userData: UserResponse }) {
  const [myComm, setMyComm] = useState<CommunityRes>();

  useEffect(() => {
    apiRequest(`/communities/community/${userData.community_id}`, "GET")
      .then((p) => setMyComm(p as CommunityRes))
      .catch((e) => console.log(e));
  }, []);

  return (
    <>
      <img
        src={myComm?.info?.banner}
        width={"100%"}
        alt=""
        style={{ height: "100px", objectFit: "cover" }}
      />
      <RColumn className="p-4">
        <RRow className="gap-4 items-center">
          <img src={myComm?.info?.image} className="w-14 rounded-lg" alt="" />
          <RColumn>
            <RRow className="gap-3">
              <Title2>{myComm?.name}</Title2>
              <Badge>${myComm?.token_symbol}</Badge>
            </RRow>
            <Description>{myComm?.info?.description}</Description>
          </RColumn>
        </RRow>
        <Space />
        <RRow className="gap-2 items-center">
          <GradientButton onClick={() => {}}>
            Buy ${myComm?.token_symbol}
          </GradientButton>
          <LuInstagram color="grey" size={26} />
          <LuTwitter color="grey" size={26} />
          <LuSend color="grey" size={26} />
          <LuGlobe color="grey" size={26} />
        </RRow>
        <Space />
        <Container>
          <RRow className="mt-2 gap-1 items-center">
            <LuTrophy size={22} color="grey" className="mr-2" />
            <Title2>5</Title2>
            <Description>Events hosted</Description>
          </RRow>
          <RRow className="mt-2 gap-1 items-center">
            <LuCircleDollarSign size={22} color="grey" className="mr-2" />
            <Title2>$2.4K</Title2>
            <Description>Rewards distribuited</Description>
          </RRow>
        </Container>
      </RColumn>
    </>
  );
}
