import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EventsApi } from "../../api/events";

const Game: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();

  const eventId = searchParams.get("eventId");

  const navigate = useNavigate();

  useEffect(() => {
    console.log(eventId);
    (window as any).Telegram.WebApp.BackButton.show();
    (window as any).Telegram.WebApp.BackButton.onClick(() => {
      if (window.history.length > 1) {
        navigate(-1);
      } else {
        navigate("/");
      }
      (window as any).Telegram.WebApp.BackButton.hide();
    });

    const messageListener = (event: MessageEvent) => {
      console.log(event);
      if (eventId && event.data.type === "game_finished") {
        console.log("submit score...");
        EventsApi.postGameScoreEvent(
          eventId,
          parseInt(id ?? "-1"),
          event.data.score
        )
          .then(() => console.log("score posted"))
          .catch((e) => console.log(e));
      }
    };

    window.addEventListener("message", messageListener);

    return () => {
      window.removeEventListener("message", messageListener);
    };
  }, []);

  return (
    <iframe
      src={
        id === "1"
          ? "HTML_version"
          : `https://tokenrivals.com/games-build/${id}`
      }
      style={{ width: "100%", height: "100vh", border: "none" }}
      title={`Game ${id}`}
      sandbox="allow-scripts allow-same-origin"
    />
  );
};

export default Game;
