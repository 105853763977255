import { LuGamepad2, LuTimer, LuTrophy, LuX } from "react-icons/lu";
import {
  Badge,
  Button,
  Container,
  Description,
  RColumn,
  RGrid,
  RRow,
  Space,
  Timer,
  Title,
  Title2,
  Title3,
} from "../../componets/baseComp";
import { useEffect, useState } from "react";
import EventComp from "../../componets/eventComp";
import { AllGamesRes, MyEventResSing, MyEventsRes } from "../../types/basetype";
import { GamesApi } from "../../api/games";
import { EventsApi } from "../../api/events";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useNavigate } from "react-router-dom";

export default function GamesPage() {
  const [games, setGames] = useState<AllGamesRes>([]);
  const [events, setEvents] = useState<MyEventsRes>([]);
  const [selectedEvent, setSelectedEvent] = useState<MyEventResSing | null>(
    null
  );

  const nav = useNavigate();

  useEffect(() => {
    GamesApi.getAllGames().then((d) => setGames(d));
    EventsApi.getMyEvents().then((d) => setEvents(d));
  }, []);

  function ChallengeGame({ event }: { event: MyEventResSing }) {
    return (
      <Container
        className="w-full p-3 my-3"
        onClick={() => setSelectedEvent(event)}
      >
        <RRow className="w-full justify-between">
          <Title2>{event.title}</Title2>
          <Timer
            dateOrEnd={event?.end_date ?? ""}
            startDate={event?.start_date}
          />
        </RRow>
        <Space height="8px" />
        <RRow className="items-end gap-1">
          <Title color="#EE9E59">{event.pool_size}</Title>
          <Title3 color="grey">{event.pool_currency}</Title3>
          <Description>prize pool</Description>
        </RRow>
        <Space />
        <RRow className="gap-1 items-center justify-start">
          <Badge>{event.type}</Badge>
          {event.games.map((g) => (
            <img src={g.image} width={"38px"} alt="" key={g.id} />
          ))}
        </RRow>
      </Container>
    );
  }
  function Game({
    data,
  }: {
    data: {
      title: string;
      badge: string;
      desc: string;
      img: string;
      id: number;
    };
  }) {
    return (
      <Container
        className="w-[48%] max-w-52 m-0"
        noGlass
        //  onClick={() => nav(`/game/${data.id}`)}
      >
        {/*     <RRow>
          <img src="game_img/crossy_road.png" width={"92px"} alt="" />
          <div className="w-6" />
          <RColumn className="w-full">
            <RRow className="justify-between w-full">
              <Title2>{data.title}</Title2>
              <Badge>{data.badge}</Badge>
            </RRow>
            <Space height="10px" />
            <Description className="text-sm">{data.desc}</Description>
          </RColumn>
        </RRow> */}
        <RColumn className="justify-center items-center gap-2">
          <img
            src={data.img}
            style={{
              height: "128px",
              width: "auto",
              objectFit: "contain",
              objectPosition: "bottom",
            }}
            alt=""
          />
          <Title3>{data.title}</Title3>
        </RColumn>
      </Container>
    );
  }
  return (
    <div
      style={{
        backgroundImage: `url('imgs/bg1.webp')`,
        backgroundSize: "140%",
        backgroundPosition: "top",
        backgroundRepeat: "no-repeat",
        minHeight: "100vh",
        width: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.9)",
      }}
    >
      {selectedEvent && (
        <div
          onClick={() => setSelectedEvent(null)}
          className="fixed w-full z-10"
          style={{
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
          }}
        >
          <div
            onClick={(e) => e.stopPropagation()}
            className="glass-background animate-slideUp pt-4"
            style={{
              width: "100%",
              zIndex: 11,
              position: "absolute",
              borderRadius: "22px 22px 0px 0px",
              height: "98vh",
              bottom: 0,
              overflowY: "auto", // Abilita lo scroll verticale
              overflowX: "hidden", // Evita lo scroll orizzontale
            }}
          >
            <LuX
              color="grey"
              size={22}
              onClick={() => setSelectedEvent(null)}
              className="ml-3 absolute"
            />
            <EventComp eventData={selectedEvent} />
          </div>
        </div>
      )}
      <div style={{ backgroundColor: "#251351", width: "100%" }}>
        <RRow className="justify-between items-center p-3">
          <img
            src="https://tokenrivals.com/wp-content/uploads/2024/11/logoTR.png"
            width={"52px"}
            alt=""
          />
          <Title3>{auth.currentUser?.displayName}</Title3>
        </RRow>
      </div>
      <Space height="20px" />

      <RRow className="pl-3 items-center gap-2">
        <LuTrophy size={24} color="orange" />
        <Title2 className="">Events</Title2>
        <Button onClick={() => signOut(auth)}>Logout</Button>
      </RRow>
      <Space height="4px" />
      <RColumn className="w-full gap-2 p-3">
        {events.map((e) => (
          <ChallengeGame key={e.id} event={e} />
        ))}
      </RColumn>
      <Space />
      <RRow className="pl-3 items-center gap-2">
        <LuGamepad2 size={24} color="orange" />
        <Title2 className="">Games</Title2>
      </RRow>
      <RGrid className="p-3 gap-3 justify-between">
        {games.map((g) => (
          <Game
            key={g.name}
            data={{
              id: g.id,
              title: g.name,
              badge: "Popular",
              desc: "Super cool and addicted game",
              img: g.image,
            }}
          />
        ))}
      </RGrid>
      <Space height="120px" />
    </div>
  );
}
