import {
  Timer,
  Badge,
  Description,
  RColumn,
  RGrid,
  RRow,
  Space,
  Title,
  Title2,
  Button,
} from "./baseComp";
import { LuTimer } from "react-icons/lu";
import Leaderboard from "./leaderboard";
import { LeaderboardEntry, MyEventResSing } from "../types/basetype";
import { useEffect, useState } from "react";
import colors from "../colors";
import { useNavigate } from "react-router-dom";
import { EventsApi } from "../api/events";

export default function EventComp({
  event_id,
  eventData,
}: {
  event_id?: string;
  eventData?: MyEventResSing;
}) {
  const nav = useNavigate();

  const [leaderboard, setLeaderboard] = useState<LeaderboardEntry[]>([]);

  const [gameSelected, setGameSelected] = useState<{
    id: number;
    name: string;
    dateAdded: string;
    image: string;
  } | null>(null);

  function handleGameClick(id: number) {
    nav(`game/${id}?eventId=${eventData?.id}`);
  }

  useEffect(() => {
    EventsApi.getEventLeaderboard(eventData?.id!).then((d) =>
      setLeaderboard(d)
    );
  }, []);

  return (
    <>
      <div className="overflow-y-auto">
        <div className="px-4">
          <RRow className="justify-end items-center gap-2">
            <Badge className="self-center">{eventData?.type}</Badge>
            <Timer
              dateOrEnd={eventData?.end_date ?? ""}
              startDate={eventData?.start_date}
            />
          </RRow>
          <Space />
          <Title>{eventData?.title}</Title>
          <RRow className="items-end gap-1">
            <Title color="#EE9E59">
              {eventData?.pool_size} {eventData?.pool_currency}
            </Title>
            <Description>prize pool</Description>
          </RRow>
          <Space />
          <Description>{eventData?.description}</Description>
          <Space />
        </div>

        <RGrid className="p-2 gap-2 justify-between">
          {eventData?.games.map((g) => (
            <div
              className="p-3 border border-white rounded-xl w-[48%]"
              key={g.id + "gg"}
              onClick={() => handleGameClick(g.id)}
            >
              <RColumn className="items-center gap-2">
                <img
                  src={g.image}
                  style={{
                    height: "94px",
                    width: "auto",
                    objectFit: "contain",
                    objectPosition: "bottom",
                  }}
                  alt=""
                />
                <Title2>{g.name}</Title2>
              </RColumn>
            </div>
          ))}
        </RGrid>
        <Space height="22px" />
        <Leaderboard data={leaderboard} />
      </div>
    </>
  );
}
