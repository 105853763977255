import { Dispatch, SetStateAction, useEffect, useState } from "react";
import "./App.css";
import Loading from "./componets/loading";
import colors from "./colors";
import {
  HashRouter,
  Route,
  Routes,
  useLocation,
  useParams,
} from "react-router-dom";
import BottomNavBar from "./componets/bottomNavBar";
import axios, { AxiosError } from "axios";
import { auth } from "./firebase";
import { signInWithCustomToken, signOut } from "firebase/auth";
import Onboarding from "./pages/onboarding/startOnboarding";
import { apiUrl } from "./utils/utils";
import { UserResponse } from "./types/basetype";
import ProfilePage from "./pages/profile/profilePage";
import GamesPage from "./pages/home/games";
import Game from "./pages/game/game";
import { Button, Title, Title3 } from "./componets/baseComp";

function App() {
  const [state, setState] = useState("loading");
  const location = useLocation();

  const [userData, setUserData]: [any, Dispatch<SetStateAction<any>>] =
    useState();

  const queryParams = new URLSearchParams(location.search);
  const command = queryParams.get("command");
  const tokenAuth = queryParams.get("tokenAuth");

  useEffect(() => {
    //tg
    try {
      (window as any).Telegram.WebApp.ready();
      (window as any).Telegram.WebApp.expand();
      (window as any).Telegram.WebApp.isVerticalSwipesEnabled = false;
    } catch (error) {}

    const unsubscribe = auth.onAuthStateChanged((user) => {
      console.log(user?.uid);
      setState("loading");
      if (user) {
        setState("authenticated");
        getBaseData();
      } else {
        if (tokenAuth && command === "start") {
          loginWithUrlParam(tokenAuth);
          return;
        }
        loginWithTelegram();
      }
    });

    return () => unsubscribe();
  }, []);

  const loginWithUrlParam = async (token: string) => {
    try {
      await signInWithCustomToken(auth, token);
    } catch (error) {
      console.log(error);
    }
  };
  const loginWithTelegram = async () => {
    setState("loading");
    try {
      let tgData = (window as any).Telegram.WebApp.initData;

      if (process.env.REACT_APP_LOCAL === "1") {
        tgData = {
          user: JSON.stringify({
            id: 123456789,
            is_bot: false,
            first_name: "John",
            last_name: "Doe",
            username: "johndoe",
            photo_url: "http://example.com/photo.jpg",
          }),
          chat: JSON.stringify({
            id: 987654321,
            type: "private",
          }),
          hash: "abcdef1234567890",
        };
      }

      const response = await axios.post(apiUrl + "/auth/login", {
        telegramInitData: tgData,
      });

      const data = response.data;
      console.log(data);
      if (data.token) {
        await signInWithCustomToken(auth, data.token);
        setState("authenticated");
      }
    } catch (error) {
      console.error("Error during login:", error);
      setState("error");
    }
  };

  const getBaseData = async () => {
    setState("loading");
    const idToken = await auth.currentUser?.getIdToken();
    try {
      const userDataRes = await axios.get(apiUrl + "/auth/user", {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      });
      const userData: UserResponse = userDataRes.data;
      setUserData(userData);

      if (!userData.walletConnected || !userData.hasCommunity) {
        setState("onboarding");
        return;
      }
      setState("home");
    } catch (e: any) {
      console.log(e);
      setState("error");
    }
    //get data, check wallet
  };
  if (state === "loading") {
    return (
      <div
        className="flex items-center justify-center h-screen"
        style={{ background: colors.gradient }}
      >
        <Loading />
      </div>
    );
  }

  if (state === "onboarding") {
    return (
      <Onboarding userData={userData!} reloadUserData={() => getBaseData()} />
    );
  }

  if (state === "error") {
    return (
      <div
        className="flex items-center justify-center h-screen"
        style={{ background: colors.gradient }}
      >
        <Title>Error</Title>
        <Button onClick={() => signOut(auth)}>Log out</Button>
      </div>
    );
  }
  if (command === "start" && tokenAuth) {
    return (
      <div
        className="flex flex-col items-center justify-center h-[80vh]"
        style={{ background: colors.gradient }}
      >
        <Title>Account set up.</Title>
        <Title3>Go back to the bot to play inside Telegram.</Title3>
      </div>
    );
  }
  return (
    <div
      style={{ background: colors.gradient, width: "100%", minHeight: "100vh" }}
    >
      <Routes>
        <Route path="/" element={<GamesPage />} />
        <Route path="/profile" element={<ProfilePage userData={userData} />} />
        <Route path="/game/:id" element={<Game />} />
        <Route
          path="/lead"
          element={
            <>
              <w3m-account-button />
            </>
          }
        />
      </Routes>

      {!location.pathname.startsWith("/game") && <BottomNavBar />}
    </div>
  );
}

export default App;
