import axios, { AxiosRequestConfig } from "axios";
import { auth } from "../firebase";

export const apiUrl = process.env.REACT_APP_API_URL;

export async function apiRequest<T>(
    endpoint: string,
    method: "GET" | "POST",
    data?: any
): Promise<T> {
    const idToken = await auth.currentUser?.getIdToken();

    if (!idToken) {
        throw new Error("User not authenticated");
    }

    const config: AxiosRequestConfig = {
        url: `${apiUrl}${endpoint}`,
        method,
        headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
        },
        data, // `data` è usato per inviare il corpo della richiesta in POST
    };

    const response = await axios(config);
    return response.data;

}

export const avatarImg = (id: string) => `https://api.dicebear.com/9.x/avataaars/svg?seed=${id}&backgroundColor=b6e3f4,c0aede,d1d4f9,ffd5dc,ffdfbf`