import { LeaderboardEntry, MyEventsRes } from "../types/basetype";
import { apiRequest } from "../utils/utils";

export class EventsApi {
    static async getMyEvents(): Promise<MyEventsRes> {
        try {
            const r: any = await apiRequest("/events/foruser", "GET");
            return r.events as MyEventsRes;
        } catch (error) {
            return [];
        }
    }

    static async postGameScoreEvent(eventId: string, gameId: number, score: number) {
        const r: any = await apiRequest("/events/sendscore", "POST", {
            eventId, score, gameId,
        });
    }
    static async getEventLeaderboard(eventId: string): Promise<LeaderboardEntry[]> {
        try {
            const r: any[] = await apiRequest(`/events/leaderboard/${eventId}`, "GET");

            const leads: LeaderboardEntry[] = r.map((d: any) => ({
                username: d.username,
                rank: d.rank,
                score: d.total_score
            }))
            return leads;
        } catch (e) {
            return [];
        }
    }

}