// src/components/Layout.tsx
import React, { useEffect, useState } from "react";
import colors from "../colors";
import { LuTimer } from "react-icons/lu";

interface LayoutProps {
  className?: string;
  children: React.ReactNode;
}

export const RRow: React.FC<LayoutProps> = ({ className = "", children }) => (
  <div className={`flex flex-row ${className}`}>{children}</div>
);

export const RColumn: React.FC<LayoutProps> = ({
  className = "",
  children,
}) => <div className={`flex flex-col ${className}`}>{children}</div>;

// src/components/Layout.tsx
interface RGridProps {
  children: React.ReactNode;
  className?: string;
  minItemWidth?: string; // Larghezza minima degli elementi (es. '150px')
}

export const RGrid: React.FC<RGridProps> = ({ children, className = "" }) => {
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
      }}
      className={`${className}`}
    >
      {children}
    </div>
  );
};

// src/components/Typography.tsx
interface TextProps {
  className?: string;
  children: React.ReactNode;
  color?: string;
}

export const Title: React.FC<TextProps> = ({
  className = "",
  children,
  color = "white",
}) => (
  <h1 className={`text-3xl font-bold ${className}`} style={{ color: color }}>
    {children}
  </h1>
);
export const Title2: React.FC<TextProps> = ({
  className = "",
  children,
  color = "white",
}) => (
  <h1 className={`text-xl font-bold ${className}`} style={{ color: color }}>
    {children}
  </h1>
);
export const Title3: React.FC<TextProps> = ({
  className = "",
  children,
  color = "white",
}) => (
  <h1 className={`text-base font-bold ${className}`} style={{ color: color }}>
    {children}
  </h1>
);
export const Description: React.FC<TextProps> = ({
  className = "",
  color = "rgb(209 213 219)",
  children,
}) => (
  <p className={`text-base ${className}`} style={{ color: color }}>
    {children}
  </p>
);

interface SpaceProps {
  height?: string; // Height of the space (e.g., '1rem', '16px', '4')
  width?: string; // Width of the space (for horizontal spacing)
}

export const Space: React.FC<SpaceProps> = ({ height, width }) => {
  return (
    <div
      style={{
        height: height || "1rem", // Default to 1rem if no height is provided
        width: width || "100%", // Default to full width if no width is provided
      }}
    />
  );
};
// src/components/Button.tsx
interface ButtonProps {
  className?: string;
  onClick: () => void;
  children: React.ReactNode;
}

export const Button: React.FC<ButtonProps> = ({
  className = "",
  onClick,
  children,
}) => (
  <button
    onClick={onClick}
    className={`text-white py-2 px-4 rounded ${colors.glassBackgroundClass} ${className}`}
  >
    {children}
  </button>
);

// src/components/Container.tsx
interface ContainerProps {
  className?: string;
  children: React.ReactNode;
  selected?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  noGlass?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  className = "",
  children,
  selected = false,
  noGlass = false,
  onClick,
}) => (
  <div
    onClick={onClick}
    className={`p-4 rounded-2xl ${className} ${
      selected ? "glass-background-color" : noGlass ? "" : "glass-background"
    }`}
  >
    {children}
  </div>
);
interface BadgeProps {
  children: React.ReactNode;
  className?: string;
  border?: string;
  bgColor?: string;
}

export const Badge: React.FC<BadgeProps> = ({
  children,
  className = "",
  border = "1px solid #15803d95",
  bgColor = "#22c55e50",
}) => {
  return (
    <div>
      <div
        className={`inline-flex px-2 py-[2px] rounded-md bg-opacity-40 text-white border border-opacity-60 items-center gap-1 w-auto  ${className}`}
        style={{ fontSize: "13px", border: border, backgroundColor: bgColor }}
      >
        {children}
      </div>
    </div>
  );
};

interface HorizontalScrollContainerProps {
  children: React.ReactNode;
  className?: string;
}

export const HorizontalScrollContainer: React.FC<
  HorizontalScrollContainerProps
> = ({ children, className = "" }) => {
  return (
    <div
      className={`flex overflow-x-auto w-full ${className}`}
      style={{
        whiteSpace: "nowrap", // Prevents line breaks, making it scrollable
        scrollSnapType: "x mandatory", // Enables snap scrolling
      }}
    >
      {React.Children.map(children, (child, index) => (
        <div
          key={index}
          className="inline-block px-2"
          style={{
            scrollSnapAlign: "start", // Aligns items at the start during scroll
          }}
        >
          {child}
        </div>
      ))}
    </div>
  );
};

interface TimerProps {
  dateOrEnd: string; // End date or single date
  startDate?: string; // Optional start date
  className?: string;
}

const calculateTimeDiff = (targetDate: Date, currentDate: Date) => {
  const diff = targetDate.getTime() - currentDate.getTime();
  const absDiff = Math.abs(diff);

  const days = Math.floor(absDiff / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (absDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((absDiff % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((absDiff % (1000 * 60)) / 1000);

  const formatted =
    days > 0
      ? `${days}d ${hours}h ${minutes}m ${seconds}s`
      : `${hours}h ${minutes}m ${seconds}s`;

  return {
    diff,
    formatted,
  };
};

export const Timer: React.FC<TimerProps> = ({
  dateOrEnd,
  startDate,
  className = "",
}) => {
  const [status, setStatus] = useState("");

  useEffect(() => {
    const targetDate = new Date(dateOrEnd);
    const start = startDate ? new Date(startDate) : null;

    const updateTimer = () => {
      const now = new Date();

      if (start && now < start) {
        // Before start date
        const { formatted } = calculateTimeDiff(start, now);
        setStatus(formatted);
      } else if (start && now >= start && now < targetDate) {
        // Between start and end
        const { formatted } = calculateTimeDiff(targetDate, now);
        setStatus(formatted);
      } else if (now >= targetDate) {
        // After end date
        setStatus("Finished");
      } else {
        const { diff, formatted } = calculateTimeDiff(targetDate, now);
        setStatus(formatted);
      }
    };

    updateTimer(); // Initial call
    const interval = setInterval(updateTimer, 1000);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [dateOrEnd, startDate]);

  return (
    <RRow className={`items-start w-32 justify-end ${className}`}>
      <LuTimer size={16} color="white" />
      <div className="w-1" />
      <Description className="text-sm text-gray-300">{status}</Description>
    </RRow>
  );
};
