import { useEffect, useState } from "react";
import { ConnectWallet } from "./connectWallet";
import { SelectComms } from "./SelectCommunity.tsx";
import { UserResponse } from "../../types/basetype";

export default function Onboarding({
  userData,
  reloadUserData,
}: {
  userData: UserResponse;
  reloadUserData: () => void;
}) {
  const [step, setStep] = useState(0);

  useEffect(() => {
    if (!userData.walletConnected) setStep(0);
    else setStep(1);
  }, []);

  if (step === 0) {
    return <ConnectWallet onConnected={() => setStep(1)} />;
  }
  if (step === 1) {
    return (
      <SelectComms
        onSelected={() => reloadUserData()}
        onChangeWallet={() => setStep(0)}
      />
    );
  }
  return <>Start</>;
}
